import React from "react";
import Layout from "../components/Layout/Layout";
import TeamCard from "../components/TeamCard/TeamCard";
import { teamData } from "../components/TeamSwiper/team-data";
import Testimonials from "../components/Testimonials/Testimonials";
import Testimonial from "../images/home/testimonial/testimonial.jpg";

import Banner from "../images/misc/zanskar_2.jpeg";

export default function Crew() {
  return (
    <Layout>
      <div className="absolute top-0 left-0 h-[500px] blur-[2px]">
        <img
          src={Banner}
          className="object-cover w-full h-full md:h-auto md:w-auto"
        />
      </div>
      <div className="container relative p-8 mx-auto text-center md:p-36">
        <h6 className="text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-yellow after:content-['♦'] after:text-kr-yellow before:pr-4 after:pl-4">
          CREW
        </h6>
        <h1 className="my-4 font-semibold text-white uppercase text-7xl">
            MEMBERS
        </h1>
      </div>
      <section className="relative pb-12 bg-neutral-800 md:py-36">
        <div className="container relative flex-col flex-wrap items-center px-4 mx-auto md:flex md:px-0">
          <div className="grid grid-cols-1 gap-8 py-12 md:grid-cols-3">
            {teamData.map((data, index) => {
              return (
                <TeamCard
                  name={data.name}
                  imgUrl={data.image}
                  designation={data.designation}
                  facebook={data.facebook}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section className="relative pb-12 bg-neutral-800 md:pb-36">
        <div className="container relative flex mx-auto max-h-[800px]">
          <div className="hidden w-full md:flex md:w-1/2">
            <div className="w-full h-full">
              <img src={Testimonial} className="object-cover w-full h-full" />
            </div>
          </div>
          <div className="w-full p-8 bg-white md:p-12 md:w-1/2">
            <h6 className="my-4 text-2xl font-light  uppercase before:content-['♦'] before:text-kr-yellow before:pr-4">
              TESTIMONIALS
            </h6>
            <h1 className="text-4xl font-semibold md:text-7xl">
              WHAT OUR <br /> MEMBERS SAY
            </h1>

            <div className="relative">
              <Testimonials />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const Head = () => <title>Crew</title>;
